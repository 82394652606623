import {
  GET_ALL_USERS,
  USER_LOADING,
  USER_ERROR,
  GET_ALL_SYSTEM_MODULES,
  GET_ALL_USER_PERMISSIONS,
  GET_ALL_USER_ROLES,
} from "../ActionTypes";
import axios from "AxiosInstance";

// import apiWrapper from "merchantApiClientWrapper";

export const setUsersLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// get all the USERS

const userData = JSON.parse(localStorage.getItem("userData"));

export const getAllAdmins = (pageSize, pageIndex) => (dispatch) => {
  dispatch(setUsersLoading());
  axios
    .get(`Admin?pageSize=${pageSize}&pageIndex=${pageIndex}`, {
      headers: {
        Authorization: `Bearer ${userData?.token}`,
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then((result) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: result.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: USER_ERROR,
        payload: err && err.response ? err.response.data : "",
      })
    );
};

// get All the system modules e.g productmanagement, usermanagement, etc..

// export const getAllUserSystemModules = () => (dispatch) => {
//   dispatch(setUsersLoading());

//   apiWrapper.user
//     .getAllUserSystemModules()
//     .then((result) => {
//       dispatch({
//         type: GET_ALL_SYSTEM_MODULES,
//         payload: result.data,
//       });
//     })
//     .catch((err) =>
//       dispatch({
//         type: USER_ERROR,
//         payload: err && err.response ? err.response.data : "",
//       })
//     );
// };

// get all the user permissions i.e. Create,Update,Delete,Read etc....

// export const getAllUserPermissions = () => (dispatch) => {
//   dispatch(setUsersLoading());
//   apiWrapper.user.getAllUserPermissions().then((result) => {
//     dispatch({
//       type: GET_ALL_USER_PERMISSIONS,
//       payload: result.data,
//     });
//   });
// };

// get all user roles for the system

// export const getAllUserRoles = () => (dispatch) => {
//   dispatch(setUsersLoading());
//   apiWrapper.user
//     .getAllUserRoles()
//     .then((result) => {
//       dispatch({
//         type: GET_ALL_USER_ROLES,
//         payload: result.data,
//       });
//     })
//     .catch((err) =>
//       dispatch({
//         type: USER_ERROR,
//         payload: err && err.response ? err.response.data : "",
//       })
//     );
// };
