import React, { useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  notification,
  Card,
  Drawer,
  Space,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import getImageUrl from "Utils/getImageUrl";

const SignUpForm = (props) => {
  const { history } = props;
  const [loading, setloading] = useState(false);
  const [loginError, setloginError] = useState(false);
  const [merchantData, setMerchantData] = useState([]);
  const [title, setTitle] = useState("");
  const { Text, Title } = Typography;
  const userData = useRef({});

  const loginSuccess = (value) => {
    localStorage.setItem("sessionId", value.sessionId);
    localStorage.setItem("merchantId", value.merchantId);
    history.push("/dashboard");
  };

  const onFinish = async (values) => {
    const { emailAddress } = values;
    values.emailAddress = emailAddress.toLowerCase();
    setloading(true);
    try {
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_MerchantDashboard_Api_URL}/Account/login`,
        values
      );

      if (loginResponse && loginResponse.data && loginResponse.status !== 200) {
        notification.error({ message: loginResponse.response.data.message });
        setloading(false);
      } else {
        if (loginResponse.data && loginResponse.data.length > 1) {
          userData.current = values;
          setMerchantData(loginResponse.data);
          setloading(false);
        } else if (loginResponse?.data.isAuthenticated) {
          loginSuccess(loginResponse.data);
        }
      }
    } catch (e) {
      setloginError(true);
      setloading(false);
    }
  };

  const getStoreLogo = (item) => {
    if (item?.storeLogoDark?.imageId) {
      return `${getImageUrl(
        item && item.storeLogoDark.imageId,
        "original",
        item.storeLogoDark.extension,
        item.merchantId
      )}`;
    } else if (item?.storeLogoLight?.imageId) {
      return `${getImageUrl(
        item.storeLogoLight.imageId,
        "original",
        item.storeLogoLight.extension,
        item.merchantId
      )}`;
    } else {
      return `https://testcdn.kuber.shop/noimage.png`; // Temporary image
    }
  };

  // call API for with merchantid
  const onSelectMerchant = async (merchantId) => {
    try {
      let selectedMerchantData = userData.current;

      selectedMerchantData.merchantId = merchantId;

      const loginWithSpecificMerchant = await axios.post(
        `${process.env.REACT_APP_MerchantDashboard_Api_URL}/Account/loginwithmerchantid`,
        selectedMerchantData
      );

      if (
        loginWithSpecificMerchant?.data &&
        loginWithSpecificMerchant.status !== 200
      ) {
        notification.error({
          message: loginWithSpecificMerchant.response.data.message,
        });
      } else {
        if (loginWithSpecificMerchant?.data?.isAuthenticated) {
          // set merchantId & session id
          loginSuccess(loginWithSpecificMerchant.data);
        }
      }
    } catch (e) {
      notification.error({
        message: e?.response?.data?.message || "Something went wrong on login.",
      });
    }
  };

  const onClose = () => {
    props.setOpen(false);
  };

  const titleMenu = (
    <Menu className="export_dw brands">
      <Menu.Item key="0" onClick={() => setTitle("Mr")}>
        Mr.
      </Menu.Item>
      <Menu.Item key="1" onClick={() => setTitle("Mrs")}>
        Mrs.
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setTitle("Miss")}>
        Miss.
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Drawer
        title="Create a new account"
        width={"33.33%"}
        onClose={onClose}
        open={props.open}
        bodyStyle={{ paddingBottom: 80 }}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button onClick={onClose} type="primary">
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        {merchantData && merchantData.length === 0 ? (
          <Form
            name="basic"
            onFinish={onFinish}
            onValuesChange={() => {
              setloginError(false);
            }}
            className="login_form_inp"
          >
            <Row
            // gutter={[0, 16]}
            // style={{
            //   height: "650px",
            //   overflow: "auto",
            // }}
            >
              <Col span={8}>
                <label className="input-label">Title</label>
                <Dropdown overlay={titleMenu} trigger={["click"]}>
                  <span>Filter</span>
                </Dropdown>
              </Col>
              <Col span={8}>
                <label className="input-label">First Name</label>
                <Form.Item
                  //  validate inputs here
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name!",
                    },
                  ]}
                  name="firstname"
                >
                  <Input className="input-field" type="text" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label className="input-label">Last Name</label>
                <Form.Item name="lastname">
                  <Input className="input-field" type="text" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label className="input-label">Email</label>
                <Form.Item
                  //  validate inputs here
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ]}
                  name="emailAddress"
                >
                  <Input className="input-field" type="email" size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label className="input-label">Password</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                  name="password"
                >
                  <Input.Password
                    className="input-field"
                    size="large"
                    style={{ position: "inherit" }}
                  />
                </Form.Item>
                {loginError && (
                  <Text style={{ color: "red" }}>
                    Please,check your email and password and try again.
                  </Text>
                )}
              </Col>
              <Col span={24}>
                <label className="input-label">Store Name</label>
                <Form.Item
                  //  validate inputs here
                  rules={[
                    {
                      required: true,
                      message: "Please enter your store name!",
                    },
                  ]}
                  name="storename"
                >
                  <Input className="input-field" type="text" size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label className="input-label">Mobile No.</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter your mobile number!",
                    },
                  ]}
                  name="mobile"
                >
                  <Input
                    className="input-field"
                    size="large"
                    style={{ position: "inherit" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div
                style={{
                  justifyContent: "flex-centre",
                  display: "flex",
                  paddingTop: "5%",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  disabled={loading}
                >
                  {loading ? `Logging In...` : ` Sign up`}
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <div>
            <Title level={3}>Please select your store :-</Title>
            <Row gutter={[16, 16]}>
              {merchantData &&
                merchantData.map((item) => {
                  return (
                    <Col span={12} className="store-img">
                      <Card
                        cover={<img alt={``} src={getStoreLogo(item)} />}
                        hoverable
                        onClick={() => {
                          onSelectMerchant(item.merchantId);
                        }}
                      >
                        <Card.Meta title={item.storeName} />
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default SignUpForm;
