// Delete Modal can be customised

import React from "react";
import { Modal, Avatar, Row, Col, Button } from "antd";
import DummyAvatar from "../Assets/dummyAvatar.jpg";
import "./modal.css";

// USAGE: {
/* <DeleteModal
visible={visible} // modal bool value
data={userData}  // name of selected option to delete e.g username , brandname etc..
callAction={DeleteUser} // method that invokes delete api
type="user"
/> */

function DeleteModal(props) {
  const { data, type, setvisible, visible, avatar } = props; //type is the user,brands,role etc... data is the name and image to display on popup

  // Call Action on Yes
  const onClickYes = () => {
    props.callAction();
  };

  const onClickNo = () => {
    if (type === "category") {
      setvisible(false);
      props.ResetDataOnClickNo();
    } else {
      setvisible(false);
    }
  };
  return (
    <Modal
      centered
      visible={visible}
      onOk={() => setvisible(false)}
      onCancel={() => onClickNo()}
      footer={null}
      width={420}
    >
      <Row gutter={[0, 16]} align="middle" justify="center">
        {avatar && (
          <Col className="col-container">
            <Avatar src={DummyAvatar} size="large" />
          </Col>
        )}
        <Col className="col-container">{data}</Col>
        <Col className="col-container">
          <p>{`Are you sure you want to delete this ${type}?`}</p>
        </Col>
      </Row>
      <Row justify="center" gutter={[15, 5]}>
        <Col>
          <Button
            type="ghost"
            onClick={() => onClickNo()}
            className="modal-button"
          >
            No
          </Button>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => onClickYes()}
            className="modal-button"
          >
            Yes
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default DeleteModal;
