// Core App Sidebar and content Structure
import React, { Component } from "react";
import { Layout, Menu, Popover, notification, Button } from "antd";
import { RightOutlined, MoreOutlined } from "@ant-design/icons";
import "./layout.css";
import logo from "Assets/klutur-logo.svg";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import GetSelectedRoute from "Utils/getDefaultSelectedRoute";
import userAvatar from "Assets/dummyAvatar.jpg";
import axios from "AxiosInstance";
import Loader from "./Loader";
import { getCurrentUserRole } from "Actions/authAction";
// import UserRole from "Utils/sideBarRoles";
// import { HashLink } from "react-router-hash-link";
import { CgArrowTopRightO } from "react-icons/cg";

class SideBar extends Component {
  state = {
    collapse: false,
    currentUser: null,
    userFound: false,
    isSessionLoggedOut: false,
    webShopUrl: "",
  };

  componentDidMount = async () => {
    try {
      const localStorageData = localStorage.getItem("userData");
      let userData;
      if (localStorageData) {
        userData = JSON.parse(localStorageData);
      }

      let response = await axios.get(
        `${process.env.REACT_APP_MerchantDashboard_Api_URL}/admin/GetCurrentAdmin`,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (response && response.status === 200) {
        const user = {
          ...response.data,
        };

        // const supplierManagement = UserRole(user.role, "Supplier Management");
        // const userManagement = UserRole(user.role, "User Management");
        // const productManagement = UserRole(user.role, "Product Management");
        // const contentManagement = UserRole(user.role, "Content Management");
        // const couponManagement = UserRole(user.role, "Coupon Management");
        // const orderManagement = UserRole(user.role, "Order Management");
        // const generalSettingsManagement = UserRole(
        //   user.role,
        //   "General Settings Management"
        // );
        // const customerManagement = UserRole(user.role, "Customer Management");

        // this.setState({
        //   supplierManagement,
        //   userManagement,
        //   productManagement,
        //   contentManagement,
        //   couponManagement,
        //   orderManagement,
        //   generalSettingsManagement,
        //   customerManagement,
        // });
        // this.props.getCurrentUserRole(user);
        this.setState({ currentUser: user });
        this.setState({ userFound: true });
      }
    } catch (res) {
      notification.error({ message: "Session is not authenticated." });
      this.setState({ isSessionLoggedOut: true });
    }

    // const merchant = await axios.get(`Merchant/GetCurrentMerchant`, {
    //   headers: {
    //     sessionId: localStorage.getItem("sessionId"),
    //   },
    // });
    // this.setState({
    //   webShopUrl: merchant && merchant.data && merchant.data.webShopUrl,
    // });
  };

  onLogoutUser = async () => {
    try {
      window.location.href = "/";
      localStorage.clear();
      // const logoutUser = await axios.post(
      //   "/session/logout",
      //   {},
      //   { headers: { sessionId: sessionId } }
      // );
      // if (logoutUser.data && logoutUser.status === 200) {
      //   // redirect to main pages
      //   window.location.href = "/";
      //   localStorage.clear();
      // } else {
      //   // set error
      //   notification.error({ message: "Something went wrong in logout!" });
      // }
    } catch (e) {
      notification.error({ message: e?.response?.data?.message });
    }
  };

  render() {
    const { Sider, Content, Header } = Layout;
    const { SubMenu } = Menu;
    const {
      collapse,
      currentUser,
      isSessionLoggedOut,
      userFound,
      userManagement,
      productManagement,
      // supplierManagement,
      // contentManagement,
      // couponManagement,
      // orderManagement,
      // generalSettingsManagement,
      // customerManagement,
    } = this.state;
    const languagecontent = (
      <div className="lg_list">
        <Link>English (UK)</Link>
      </div>
    );
    const currencycontent = (
      <div className="lg_list">
        <Link href="#">GBP</Link>
      </div>
    );

    if (isSessionLoggedOut) {
      return <Navigate to="/" />;
    }

    // if (!userFound) {
    //   return <Loader />;
    // }
    return (
      <Layout hasSider={true}>
        <Sider
          width={287}
          trigger={
            <div>
              <RightOutlined style={{ color: "#EB4A1C" }} />
            </div>
          }
          className={collapse ? "responsiveSider" : "fixedSider"}
          breakpoint="md"
          collapsedWidth="0"
          theme="light"
          onBreakpoint={(broken) => {
            if (broken) this.setState({ collapse: true });
            else this.setState({ collapse: false });
          }}
        >
          <div className="kuber-logo-container">
            <img src={logo} className="app-logo" alt="logo" />
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={GetSelectedRoute(this.props.pathname)}
            className="kuber_leftbar_menu"
          >
            <Menu.Item
              key="1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="b"
                      cx="9"
                      cy="9"
                      r="9"
                      transform="translate(3 3)"
                    />
                    <circle
                      className="b"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(11 11)"
                    />
                    <line
                      className="b"
                      y1="2.59"
                      x2="2.59"
                      transform="translate(13.41 8)"
                    />
                    <path className="b" d="M7,12a5,5,0,0,1,5-5" />
                  </svg>
                </div>
                <Link to="/dashboard">Dashboard</Link>
              </span>
            </Menu.Item>

            <SubMenu
              key="sub2"
              title={
                <span className="sub_icon">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_301"
                      data-name="Group 301"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <rect
                        id="Rectangle_731"
                        data-name="Rectangle 731"
                        className="b"
                        width="14"
                        height="18"
                        rx="2"
                        transform="translate(5 3)"
                      />
                      <line
                        id="Line_15"
                        data-name="Line 15"
                        className="b"
                        x2="6"
                        transform="translate(9 7)"
                      />
                      <line
                        id="Line_16"
                        data-name="Line 16"
                        className="b"
                        x2="6"
                        transform="translate(9 11)"
                      />
                      <line
                        id="Line_17"
                        data-name="Line 17"
                        className="b"
                        x2="4"
                        transform="translate(9 15)"
                      />
                    </svg>
                  </div>
                  <span>Catalogue</span>
                </span>
              }
            >
              <Menu.Item key="5">
                <Link to="/catalogue/products">Products</Link>
              </Menu.Item>
              <Menu.Item key="10">
                <Link to="/catalogue/categories">Categories</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/catalogue/brands">Brands</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/catalogue/attributes">Attributes</Link>
              </Menu.Item>
              <Menu.Item key="7">
                <Link to="/catalogue/parcelsize">Parcel Size</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to="/catalogue/productcondition">Product Condition</Link>
              </Menu.Item>
              {/* <Menu.Item key="11">
                <Link to="/orders">Orders</Link>
              </Menu.Item> */}
              {/* {supplierManagement && supplierManagement.length && (
                <Menu.Item key="7">
                  <Link to="/catalogue/suppliers">Suppliers</Link>
                </Menu.Item>
              )}
              {productManagement && productManagement.length && (
                <Menu.Item key="8">
                  <Link to="/catalogue/stores">Stores</Link>
                </Menu.Item>
              )}
              {productManagement && productManagement.length && (
                <Menu.Item key="9">
                  <Link to="/catalogue/attributes">Attributes</Link>
                </Menu.Item>
              )}
              {productManagement && productManagement.length && (
                <Menu.Item key="10">
                  <Link to="/catalogue/categories">Categories</Link>
                </Menu.Item>
              )}
              {productManagement && productManagement.length && (
                <Menu.Item key="23">
                  <Link to="/catalogue/inventory">Inventory</Link>
                </Menu.Item>
              )}
              {productManagement &&
                productManagement.length &&
                productManagement.includes("Update") && (
                  <Menu.Item key="34">
                    <Link to="/catalogue/import">Import </Link>
                  </Menu.Item>
                )}
              {productManagement &&
                productManagement.length &&
                productManagement.includes("Update") && (
                  <Menu.Item key="35">
                    <Link to="/catalogue/excelimport">Import Excel</Link>
                  </Menu.Item>
                )} */}
            </SubMenu>

            {/*
            {orderManagement && orderManagement.length && (
              <SubMenu
                key="sub3"
                title={
                  <span className="sub_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_310"
                      data-name="Group 310"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_399"
                        data-name="Path 399"
                        className="db_icon"
                        d="M0,0H24V24H0Z"
                      />
                      <path
                        id="Path_400"
                        data-name="Path 400"
                        className="b"
                        d="M12,3l8,4.5v9L12,21,4,16.5v-9L12,3"
                      />
                      <line
                        id="Line_22"
                        data-name="Line 22"
                        className="b"
                        y1="4.5"
                        x2="8"
                        transform="translate(12 7.5)"
                      />
                      <line
                        id="Line_23"
                        data-name="Line 23"
                        className="b"
                        y2="9"
                        transform="translate(12 12)"
                      />
                      <line
                        id="Line_24"
                        data-name="Line 24"
                        className="b"
                        x1="8"
                        y1="4.5"
                        transform="translate(4 7.5)"
                      />
                      <line
                        id="Line_25"
                        data-name="Line 25"
                        className="b"
                        x1="8"
                        y2="4.5"
                        transform="translate(8 5.25)"
                      />
                    </svg>
                    <span>Orders</span>
                  </span>
                }
              >
                <Menu.Item key="11">
                  <Link to="/orders">Orders List</Link>
                </Menu.Item>
                <Menu.Item key="51">
                  <Link to="/invoices">Invoices</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {contentManagement && contentManagement.length && (
              <SubMenu
                key="sub5"
                title={
                  <span className="sub_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_307"
                      data-name="Group 307"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_394"
                        data-name="Path 394"
                        className="db_icon"
                        d="M0,0H24V24H0Z"
                      />
                      <path
                        id="Path_395"
                        data-name="Path 395"
                        className="b"
                        d="M9,4h3l2,2h5a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H9a2,2,0,0,1-2-2V6A2,2,0,0,1,9,4"
                      />
                      <path
                        id="Path_396"
                        data-name="Path 396"
                        className="b"
                        d="M17,17v2a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V10A2,2,0,0,1,5,8H7"
                      />
                    </svg>
                    <span>Contents</span>
                  </span>
                }
              >
                <Menu.Item key="21">
                  <Link to="/content/pages">Pages</Link>
                </Menu.Item>
                <Menu.Item key="22">
                  <Link to="/content/news">News</Link>
                </Menu.Item>
                <Menu.Item key="26">
                  <Link to="/content/news/addnewcategory">News Category</Link>
                </Menu.Item>
              </SubMenu>
            )} */}
            <SubMenu
              key="sub4"
              title={
                <span className="sub_icon">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_4165"
                      data-name="Group 4165"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        id="Ellipse_188"
                        data-name="Ellipse 188"
                        className="b"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(5 3)"
                      />
                      <path
                        id="Path_4414"
                        data-name="Path 4414"
                        className="b"
                        d="M5,22V17L4,16V12a1,1,0,0,1,1-1H9a1,1,0,0,1,1,1v4L9,17v5"
                      />
                      <circle
                        id="Ellipse_189"
                        data-name="Ellipse 189"
                        className="b"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(15 3)"
                      />
                      <path
                        id="Path_4415"
                        data-name="Path 4415"
                        className="b"
                        d="M15,22V18H13l2-6a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1l2,6H19v4"
                      />
                    </svg>
                  </div>
                  <span>Users</span>
                </span>
              }
            >
              <Menu.Item key="16">
                <Link to="/user/list">Users List</Link>
              </Menu.Item>
              {userManagement &&
                userManagement.length &&
                userManagement.includes("Create") && (
                  <Menu.Item key="17">
                    <Link to="/user/add">Add User</Link>
                  </Menu.Item>
                )}
              {/* <Menu.Item key="18">
                <Link to="/user/role"> User Roles</Link>
              </Menu.Item> */}
            </SubMenu>
            <SubMenu
              key="sub5"
              title={
                <span className="sub_icon">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_306"
                      data-name="Group 306"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        id="Ellipse_16"
                        data-name="Ellipse 16"
                        className="b"
                        cx="4"
                        cy="4"
                        r="4"
                        transform="translate(5 3)"
                      />
                      <path
                        id="Path_391"
                        data-name="Path 391"
                        className="b"
                        d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2"
                      />
                      <path
                        id="Path_392"
                        data-name="Path 392"
                        className="b"
                        d="M16,3.13a4,4,0,0,1,0,7.75"
                      />
                      <path
                        id="Path_393"
                        data-name="Path 393"
                        className="b"
                        d="M21,21V19a4,4,0,0,0-3-3.85"
                      />
                    </svg>
                  </div>
                  <span>Admins</span>
                </span>
              }
            >
              <Menu.Item key="19">
                <Link to="/admin/list">Admins List</Link>
              </Menu.Item>
              {/* {userManagement &&
                userManagement.length &&
                userManagement.includes("Create") && ( */}
              <Menu.Item key="20">
                <Link to="/admin/add">Add Admin</Link>
              </Menu.Item>
              {/* )} */}
              {/* <Menu.Item key="18">
                <Link to="/user/role"> User Roles</Link>
              </Menu.Item> */}
            </SubMenu>
            <SubMenu
              key="sub25"
              title={
                <span className="sub_icon">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_4370"
                      data-name="Group 4370"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_4529"
                        data-name="Path 4529"
                        className="db_icon"
                        d="M0,0H24V24H0Z"
                      />
                      <rect
                        id="Rectangle_1564"
                        data-name="Rectangle 1564"
                        className="b"
                        width="16"
                        height="4"
                        rx="1"
                        transform="translate(4 4)"
                      />
                      <rect
                        id="Rectangle_1565"
                        data-name="Rectangle 1565"
                        className="b"
                        width="6"
                        height="8"
                        rx="1"
                        transform="translate(4 12)"
                      />
                      <line
                        id="Line_373"
                        data-name="Line 373"
                        className="b"
                        x2="6"
                        transform="translate(14 12)"
                      />
                      <line
                        id="Line_374"
                        data-name="Line 374"
                        className="b"
                        x2="6"
                        transform="translate(14 16)"
                      />
                      <line
                        id="Line_375"
                        data-name="Line 375"
                        className="b"
                        x2="6"
                        transform="translate(14 20)"
                      />
                    </svg>
                  </div>
                  <span>Content</span>
                </span>
              }
            >
              <Menu.Item key="25">
                <Link to="/content/helpcenter">Help center</Link>
              </Menu.Item>
              <Menu.Item key="26">
                <Link to="/content/helpcenter/page">Content page</Link>
              </Menu.Item>
              <Menu.Item key="27">
                <Link to="/content/helpcenter/page/list">Pages List</Link>
              </Menu.Item>
              <Menu.Item key="28">
                <Link to="/content/howitworks">How it works</Link>
              </Menu.Item>
              {/* {userManagement &&
                userManagement.length &&
                userManagement.includes("Create") && ( */}
              {/* <Menu.Item key="20">
                <Link to="/admin/add">Add Admin</Link>
              </Menu.Item> */}
              {/* )} */}
              {/* <Menu.Item key="18">
                <Link to="/user/role"> User Roles</Link>
              </Menu.Item> */}
            </SubMenu>
            <Menu.Item
              key="sub3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_310"
                    data-name="Group 310"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_400"
                      data-name="Path 400"
                      className="b"
                      d="M12,3l8,4.5v9L12,21,4,16.5v-9L12,3"
                    />
                    <line
                      id="Line_22"
                      data-name="Line 22"
                      className="b"
                      y1="4.5"
                      x2="8"
                      transform="translate(12 7.5)"
                    />
                    <line
                      id="Line_23"
                      data-name="Line 23"
                      className="b"
                      y2="9"
                      transform="translate(12 12)"
                    />
                    <line
                      id="Line_24"
                      data-name="Line 24"
                      className="b"
                      x1="8"
                      y1="4.5"
                      transform="translate(4 7.5)"
                    />
                    <line
                      id="Line_25"
                      data-name="Line 25"
                      className="b"
                      x1="8"
                      y2="4.5"
                      transform="translate(8 5.25)"
                    />
                  </svg>
                </div>
                <Link to="/orders">Orders</Link>
              </span>
            </Menu.Item>
            <Menu.Item
              key="25"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_4432"
                    data-name="Group 4432"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <line
                      id="Line_419"
                      data-name="Line 419"
                      className="b"
                      y2="2"
                      transform="translate(15 5)"
                    />
                    <line
                      id="Line_420"
                      data-name="Line 420"
                      className="b"
                      y2="2"
                      transform="translate(15 11)"
                    />
                    <line
                      id="Line_421"
                      data-name="Line 421"
                      className="b"
                      y2="2"
                      transform="translate(15 17)"
                    />
                    <path
                      id="Path_4565"
                      data-name="Path 4565"
                      className="b"
                      d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,1,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,1,0,0-4V7A2,2,0,0,1,5,5"
                      transform="translate(0 0)"
                    />
                  </svg>
                </div>
                <Link to="/platformfee">Platform Fee</Link>
              </span>
            </Menu.Item>
            <Menu.Item
              key="26"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M13 3a1 1 0 1 0-2 0v.75h-.557A4.214 4.214 0 0 0 6.237 7.7l-.221 3.534a7.377 7.377 0 0 1-1.308 3.754a1.617 1.617 0 0 0 1.135 2.529l3.407.408V19a2.75 2.75 0 1 0 5.5 0v-1.075l3.407-.409a1.617 1.617 0 0 0 1.135-2.528a7.376 7.376 0 0 1-1.308-3.754l-.221-3.533a4.214 4.214 0 0 0-4.206-3.951H13V3Zm-2.557 2.25a2.714 2.714 0 0 0-2.709 2.544l-.22 3.534a8.877 8.877 0 0 1-1.574 4.516a.117.117 0 0 0 .082.183l3.737.449c1.489.178 2.993.178 4.482 0l3.737-.449a.117.117 0 0 0 .082-.183a8.877 8.877 0 0 1-1.573-4.516l-.221-3.534a2.714 2.714 0 0 0-2.709-2.544h-3.114Zm1.557 15c-.69 0-1.25-.56-1.25-1.25v-.75h2.5V19c0 .69-.56 1.25-1.25 1.25Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <Link to="/notifications">Notifications</Link>
              </span>
            </Menu.Item>
            {/* <SubMenu
              key="sub6"
              title={
                <span className="sub_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_300"
                    data-name="Group 300"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_385"
                      data-name="Path 385"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <line
                      id="Line_14"
                      data-name="Line 14"
                      className="b"
                      x2="16"
                      transform="translate(4 19)"
                    />
                    <path
                      id="Path_386"
                      data-name="Path 386"
                      className="b"
                      d="M4,15,8,9l4,2,4-5,4,4"
                    />
                  </svg>
                  <span>Report</span>
                </span>
              }
            >
              <Menu.Item key="28">
                <Link to="/report/general">General</Link>
              </Menu.Item>
            </SubMenu>
            {customerManagement && customerManagement.length && (
              <SubMenu
                key="sub7"
                title={
                  <span className="sub_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_4165"
                      data-name="Group 4165"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_4413"
                        data-name="Path 4413"
                        className="db_icon"
                        d="M0,0H24V24H0Z"
                      />
                      <circle
                        id="Ellipse_188"
                        data-name="Ellipse 188"
                        className="b"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(5 3)"
                      />
                      <path
                        id="Path_4414"
                        data-name="Path 4414"
                        className="b"
                        d="M5,22V17L4,16V12a1,1,0,0,1,1-1H9a1,1,0,0,1,1,1v4L9,17v5"
                      />
                      <circle
                        id="Ellipse_189"
                        data-name="Ellipse 189"
                        className="b"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(15 3)"
                      />
                      <path
                        id="Path_4415"
                        data-name="Path 4415"
                        className="b"
                        d="M15,22V18H13l2-6a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1l2,6H19v4"
                      />
                    </svg>
                    <span>Customers</span>
                  </span>
                }
              >
                <Menu.Item key="31">
                  <Link to="/customers/customerslist">Customers List</Link>
                </Menu.Item>
                <Menu.Item key="32">
                  <Link to="/reviews">Reviews</Link>
                </Menu.Item>
              </SubMenu>
            )}
            <Menu.Item
              key="36"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Group_4356"
                  data-name="Group 4356"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_4476"
                    data-name="Path 4476"
                    className="db_icon"
                    d="M0,0H24V24H0Z"
                  />
                  <rect
                    id="Rectangle_1553"
                    data-name="Rectangle 1553"
                    className="b"
                    width="16"
                    height="16"
                    rx="2"
                    transform="translate(4 4)"
                  />
                  <path
                    id="Path_4477"
                    data-name="Path 4477"
                    className="b"
                    d="M4,12,7,9a2.015,2.015,0,0,1,3,0l4,4"
                    transform="translate(0 3)"
                  />
                  <path
                    id="Path_4478"
                    data-name="Path 4478"
                    className="b"
                    d="M13,12l2-2a2.015,2.015,0,0,1,3,0l2,2"
                    transform="translate(0 3)"
                  />
                  <line
                    id="Line_407"
                    data-name="Line 407"
                    className="b"
                    x2="0.01"
                    transform="translate(14 10)"
                  />
                </svg>
                <Link to="/mediagallery">Media</Link>
              </span>
            </Menu.Item>
            {contentManagement && contentManagement.length && (
              <Menu.Item
                key="24"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="sub_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_4370"
                    data-name="Group 4370"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_4529"
                      data-name="Path 4529"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <rect
                      id="Rectangle_1564"
                      data-name="Rectangle 1564"
                      className="b"
                      width="16"
                      height="4"
                      rx="1"
                      transform="translate(4 4)"
                    />
                    <rect
                      id="Rectangle_1565"
                      data-name="Rectangle 1565"
                      className="b"
                      width="6"
                      height="8"
                      rx="1"
                      transform="translate(4 12)"
                    />
                    <line
                      id="Line_373"
                      data-name="Line 373"
                      className="b"
                      x2="6"
                      transform="translate(14 12)"
                    />
                    <line
                      id="Line_374"
                      data-name="Line 374"
                      className="b"
                      x2="6"
                      transform="translate(14 16)"
                    />
                    <line
                      id="Line_375"
                      data-name="Line 375"
                      className="b"
                      x2="6"
                      transform="translate(14 20)"
                    />
                  </svg>
                  <Link to="/templates">Website Templates</Link>
                </span>
              </Menu.Item>
            )}
            {couponManagement && couponManagement.length && (
              <Menu.Item
                key="25"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="sub_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_4432"
                    data-name="Group 4432"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_4564"
                      data-name="Path 4564"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <line
                      id="Line_419"
                      data-name="Line 419"
                      className="b"
                      y2="2"
                      transform="translate(15 5)"
                    />
                    <line
                      id="Line_420"
                      data-name="Line 420"
                      className="b"
                      y2="2"
                      transform="translate(15 11)"
                    />
                    <line
                      id="Line_421"
                      data-name="Line 421"
                      className="b"
                      y2="2"
                      transform="translate(15 17)"
                    />
                    <path
                      id="Path_4565"
                      data-name="Path 4565"
                      className="b"
                      d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,1,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,1,0,0-4V7A2,2,0,0,1,5,5"
                      transform="translate(0 0)"
                    />
                  </svg>
                  <Link to="/coupons">Coupons</Link>
                </span>
              </Menu.Item>
            )}
            {generalSettingsManagement && generalSettingsManagement.length && (
              <Menu.Item
                key="20"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="sub_icon">
                  <svg
                    id="Group_305"
                    data-name="Group 305"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_388"
                      data-name="Path 388"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <path
                      id="Path_389"
                      data-name="Path 389"
                      className="b"
                      d="M10.325,4.317a1.724,1.724,0,0,1,3.35,0,1.724,1.724,0,0,0,2.573,1.066,1.725,1.725,0,0,1,2.37,2.37,1.724,1.724,0,0,0,1.065,2.572,1.724,1.724,0,0,1,0,3.35,1.724,1.724,0,0,0-1.066,2.573,1.725,1.725,0,0,1-2.37,2.37,1.724,1.724,0,0,0-2.572,1.065,1.724,1.724,0,0,1-3.35,0,1.724,1.724,0,0,0-2.573-1.066,1.725,1.725,0,0,1-2.37-2.37,1.724,1.724,0,0,0-1.065-2.572,1.724,1.724,0,0,1,0-3.35A1.724,1.724,0,0,0,5.383,7.752a1.725,1.725,0,0,1,2.37-2.37,1.723,1.723,0,0,0,2.572-1.065Z"
                    />
                    <circle
                      id="Ellipse_15"
                      data-name="Ellipse 15"
                      className="b"
                      cx="3"
                      cy="3"
                      r="3"
                      transform="translate(9 9)"
                    />
                  </svg>
                  <Link to="/settings">Settings</Link>
                </span>
              </Menu.Item>
            )}
            <SubMenu
              key="sub8"
              title={
                <span className="sub_icon">
                  <span>How to set up?</span>
                </span>
              }
              className="howtosetup_list"
            >
              <Menu.Item key="40">
                <HashLink smooth to="/introductionguide#domain">
                  Domain
                </HashLink>
              </Menu.Item>
              <Menu.Item key="41">
                <HashLink smooth to="/introductionguide#store-settings">
                  Store Settings
                </HashLink>
              </Menu.Item>
              <Menu.Item key="42">
                <HashLink smooth to="/introductionguide#other-settings">
                  Other Settings
                </HashLink>
              </Menu.Item>
              <Menu.Item key="43">
                <HashLink smooth to="/introductionguide#international-market">
                  International Market
                </HashLink>
              </Menu.Item>
              <Menu.Item key="44">
                <HashLink smooth to="/introductionguide#tax-rates">
                  Tax Rates
                </HashLink>
              </Menu.Item>
              <Menu.Item key="45">
                <HashLink smooth to="/introductionguide#shipping">
                  Shipping
                </HashLink>
              </Menu.Item>
              <Menu.Item key="46">
                <HashLink smooth to="/introductionguide#legal-pages">
                  Legal Pages
                </HashLink>
              </Menu.Item>
              <Menu.Item key="47">
                <HashLink smooth to="/introductionguide#third-party-tools">
                  Third Party Tools
                </HashLink>
              </Menu.Item>
              <Menu.Item key="48">
                <HashLink smooth to="/introductionguide#create-coupon">
                  Create Coupon
                </HashLink>
              </Menu.Item>
              <Menu.Item key="49">
                <HashLink smooth to="/introductionguide#import-catalogue">
                  Import Catalogue
                </HashLink>
              </Menu.Item>
              <Menu.Item key="50">
                <HashLink smooth to="/introductionguide#choose-theme">
                  Choose Theme
                </HashLink>
              </Menu.Item>
            </SubMenu> */}
          </Menu>
          <div className="user_profile">
            <div className="user_hv">
              <div className="user_inner">
                <div className="user_img">
                  <img src={userAvatar} className="user_in_img" alt="logo" />
                </div>
                <div className="user_dsc">
                  <span>
                    {currentUser?.firstName}{" "}
                    {currentUser?.lastName ? currentUser?.lastName : ""}
                  </span>
                  {/* <p>{currentUser.role?.name}</p> */}
                </div>
                <div className="user_icon">
                  <MoreOutlined />
                </div>
              </div>
              <div className="user_link">
                <Link onClick={this.onLogoutUser}>Log out</Link>
              </div>
            </div>
          </div>
        </Sider>
        <Layout
          style={{
            background: "#F5F7FA",
          }}
          className="main_layout"
        >
          <Header className="top_bar_main">
            <div className="search-bar"></div>

            <div className="top_right_bar">
              <ul>
                <li>
                  <div className="set_bx">
                    <Popover
                      placement="bottomLeft"
                      content={languagecontent}
                      trigger="click"
                    >
                      <svg
                        id="Group_4118"
                        data-name="Group 4118"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_4407"
                          data-name="Path 4407"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_4408"
                          data-name="Path 4408"
                          d="M5,7h7M10,5V7c0,4.418-2.239,8-5,8m1-4c0,2.144,2.952,3.908,6.7,4"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_4409"
                          data-name="Path 4409"
                          d="M11,19l4-9,4,9m-.9-2H11.9"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span>English (UK)</span>
                    </Popover>
                  </div>
                </li>
                <li>
                  <div className="set_bx">
                    <Popover
                      placement="bottomLeft"
                      content={currencycontent}
                      trigger="click"
                    >
                      <svg
                        id="Group_4121"
                        data-name="Group 4121"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_4410"
                          data-name="Path 4410"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <rect
                          id="Rectangle_1506"
                          data-name="Rectangle 1506"
                          width="14"
                          height="10"
                          rx="2"
                          transform="translate(7 9)"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <circle
                          id="Ellipse_186"
                          data-name="Ellipse 186"
                          cx="2"
                          cy="2"
                          r="2"
                          transform="translate(12 12)"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_4411"
                          data-name="Path 4411"
                          d="M17,9V7a2,2,0,0,0-2-2H5A2,2,0,0,0,3,7v6a2,2,0,0,0,2,2H7"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span>GBP</span>
                    </Popover>
                  </div>
                </li>
                <li>
                  {this?.state?.webShopUrl && (
                    <div className="store-btn">
                      <a
                        href={`${this?.state?.webShopUrl}`}
                        target="blank"
                        type="Button"
                      >
                        <Button>
                          <CgArrowTopRightO />
                          Your store
                        </Button>
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </Header>
          <Content className={collapse ? "mobileContent" : "content all_pd"}>
            <div>{this.props.children}</div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pathname: state.router.location.pathname,
  };
};

export default connect(mapStateToProps, { getCurrentUserRole })(SideBar);
