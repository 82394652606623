// Main Login Screen

import React from "react";
import { Row, Col, Typography } from "antd";
import SignUpForm from "./SignUpForm";

export default function SignUp(props) {
  const { Text, Title } = Typography;
  return (
    <>
      {/* <div>Hello</div> */}
      <Row align="middle" className="login-container">
        <Col lg={16} md={12} sm={24} className="login-background-image">
          <Row style={{ padding: "8%" }}>
            <Col
              xxl={{ span: 12, offset: 2 }}
              lg={24}
              className="kuber-logo-login"
              md={12}
            />
            <Col xxl={{ span: 12, offset: 2 }} lg={24} md={24}>
              <Title
                level={1}
                className="textOverlay"
                style={{ color: "white" }}
              >
                Enjoy your own store for FREE <br />
                and let's grow your business.
              </Title>
            </Col>
            <Col xxl={{ span: 12, offset: 2 }} lg={24}>
              <Text style={{ color: "white" }}>
                Tackle the complexity of managing an online shop with Kuber.
                <br /> Login to control everything about your catalogue and
                marketing activities.
              </Text>
            </Col>
          </Row>
        </Col>
        {/* <Col xl={8} lg={8} md={12} sm={24} className="right_login_form">
          <Row justify="center">
            <Col span={20}>
              <Title level={3}>Register your account</Title>
            </Col>
            <Col span={20}>
              <SignUpForm history={props.history} />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
}
