// Main Login Screen

import React from "react";
import { Row, Col, Typography } from "antd";
import LoginForm from "./LoginForm";
import "./login.css";
import { Link } from "react-router-dom";
import logo from "Assets/klutur-logo.svg";

export default function Login(props) {
  const { Text, Title } = Typography;
  return (
    <>
      {/* <div>Hello</div> */}
      <Row className="login-container">
        <Col
          span={24}
          lg={12}
          className="login-background-image hp-position-relative hp-bg-color-1"
        >
          <Row className="hp-h-100">
            <Col className="hp-logo-item">
              <div className="header-logo">
                <Link to={"/"}>
                  <img src={logo} className="app-logo" alt="logo" />
                </Link>
              </div>
            </Col>
            <Col xl={15} sm={24} md={22} lg={22}>
              <div className="left_text">
                <Title className="textOverlay">
                  Enjoy your own store for FREE and let's grow your business.
                </Title>
                <Text>
                  Tackle the complexity of managing an online shop with Kuber.
                  Login to control everything about your catalogue and marketing
                  activities.
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12} className="right_login_form">
          <Row className="hp-h-100" justify="center">
            <Col xl={15} sm={24} md={20} lg={20}>
              <div className="right-login">
                <Title level={3}>Welcome to Klutur</Title>
                <Text>
                  Here is how we make it easy to open your own dream store.
                </Text>
                <LoginForm history={props.history} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
