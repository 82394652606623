// Wrapper over route to check the session id and check auth of the user
// all the things needed at the time of component call...

import React from "react";
import { Navigate, Route, Outlet } from "react-router-dom";
import Dashboard from "./Screens/Dashboard/Dashboard";

import SideBar from "../src/Layouts/SideBar";

const PrivateRoute = (props) => {
  const { component, ...rest } = props;

  const userData = JSON.parse(localStorage.getItem("userData"));
  const sessionId = localStorage.getItem("sessionId");
  const localStorageData = localStorage.getItem("userData");
  return userData?.token ? (
    <SideBar>
      <Outlet />
    </SideBar>
  ) : (
    <Navigate to="/" />
  );
  // if (merchantId && sessionId) {
  //   return (
  //     <SideBar>
  //       <Outlet>
  //         <Route element={component} {...rest} />
  //       </Outlet>
  //     </SideBar>
  //   );
  // } else {
  //   return <Navigate to="/" />;
  // }
};

export default PrivateRoute;
