import React, { Component, Suspense } from "react";
import "./custom.css";
import store, { history } from "./store";
import CacheBuster from "./components/cacheBuster";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./Screens/Login/Login";
import SignUp from "./Screens/SignUp/SignUp";
import PageNotFound from "./Screens/PageNotFound";
import Dashboard from "./Screens/Dashboard/Dashboard";
import UserList from "./Screens/Users/UserList";
import AdminList from "./Screens/Admins/AdminList";
// import AddUser from "./Screens/Users/AddUser";
import PrivateRoute from "./PrivateRoute";

const UserDetails = React.lazy(() => import("Screens/Users/UserDetails"));
const ProductDetail = React.lazy(() =>
  import("Screens/Catalogue/Products/ProductDetail")
);
const AddAdmin = React.lazy(() => import("Screens/Admins/AddAdmin"));
const ProductsList = React.lazy(() =>
  import("Screens/Catalogue/Products/ProductsList")
);
const CategoryList = React.lazy(() =>
  import("Screens/Catalogue/Categories/CategoriesList")
);
const AddProduct = React.lazy(() =>
  import("Screens/Catalogue/Products/CreateProduct/AddProduct")
);
const BrandsList = React.lazy(() =>
  import("Screens/Catalogue/Brands/BrandsList")
);
const AttributeList = React.lazy(() =>
  import("Screens/Catalogue/Attributes/AttributesList")
);
const AddAttribute = React.lazy(() =>
  import("Screens/Catalogue/Attributes/AddAttribute")
);

const AddBrands = React.lazy(() => import("Screens/Catalogue/Brands/AddBrand"));
const ParcelSizeList = React.lazy(() =>
  import("Screens/Catalogue/ParcelSizes/ParcelSizeList")
);
const AddParcelSize = React.lazy(() =>
  import("Screens/Catalogue/ParcelSizes/AddParcelSize")
);
const ProductConditionList = React.lazy(() =>
  import("Screens/Catalogue/ProductCondition/ProductConditionList")
);
const AddProductCondition = React.lazy(() =>
  import("Screens/Catalogue/ProductCondition/AddProductCondition")
);
const OrdersList = React.lazy(() => import("Screens/Order/OrdersList"));
const OrderDetail = React.lazy(() => import("Screens/Order/OrderDetail"));
const PlatformFeeList = React.lazy(() =>
  import("Screens/PlatformFee/PlatformFeeList")
);
const AddPlatformFee = React.lazy(() =>
  import("Screens/PlatformFee/AddPlatformFee")
);
const NotificationsList = React.lazy(() =>
  import("Screens/Notifications/NotificationsList")
);
const HelpCenter = React.lazy(() => import("Screens/Content/HelpCenter"));
const HelpCenterPages = React.lazy(() => import("Screens/Content/AddPage"));
const HelpCenterPagesList = React.lazy(() =>
  import("Screens/Content/PagesList")
);
const HowItWorks = React.lazy(() => import("Screens/Comingsoon"));

export default class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            <div className="app">
              <Provider store={store}>
                <ErrorBoundary>
                  <Suspense>
                    <Routes history={history}>
                      <Route path="/" element={<Login history={history} />} />
                      <Route
                        path="/signup"
                        element={<SignUp history={SignUp} />}
                      />
                      {/* Page Not Found */}
                      <Route exact path="*" element={<PageNotFound />} />
                      <Route exact path="/dashboard" element={<PrivateRoute />}>
                        <Route
                          exact
                          path="/dashboard"
                          element={<Dashboard />}
                        />
                      </Route>
                      <Route exact path="/orders" element={<PrivateRoute />}>
                        <Route exact path="/orders" element={<OrdersList />} />
                      </Route>
                      <Route exact path="/order/:id" element={<PrivateRoute />}>
                        <Route
                          exact
                          path="/order/:id"
                          element={<OrderDetail />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/user/list"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/user/list"
                          element={<UserList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/users/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/users/:id"
                          element={<UserDetails history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/platformfee"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/platformfee"
                          element={<PlatformFeeList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/platformfee/add"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/platformfee/add"
                          element={<AddPlatformFee history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/platformfee/edit/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/platformfee/edit/:id"
                          element={<AddPlatformFee history={history} />}
                        />
                      </Route>
                      {/* <Route
                        exact
                        path="/user/edit/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/user/edit/:id"
                          element={<AddUser history={history} />}
                        />
                      </Route> */}
                      <Route
                        exact
                        path="/catalogue/products"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/products"
                          element={<ProductsList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/product/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/product/:id"
                          element={<ProductDetail history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/products/add"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/products/add"
                          element={<AddProduct history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/categories"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/categories"
                          element={<CategoryList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/brands"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/brands"
                          element={<BrandsList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/brands/add"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/brands/add"
                          element={<AddBrands history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/brands/edit/:id"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/brands/edit/:id"
                          element={<AddBrands history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/parcelsize"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/parcelsize"
                          element={<ParcelSizeList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/parcelsize/add"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/parcelsize/add"
                          element={<AddParcelSize history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/parcelsize/edit/:id"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/parcelsize/edit/:id"
                          element={<AddParcelSize history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/productcondition"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/productcondition"
                          element={<ProductConditionList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/productcondition/add"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/productcondition/add"
                          element={<AddProductCondition history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/productcondition/edit/:id"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/productcondition/edit/:id"
                          element={<AddProductCondition history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/attributes"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/attributes"
                          element={<AttributeList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/attribute/add"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/attribute/add"
                          element={<AddAttribute history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/catalogue/attribute/edit/:id"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/attribute/edit/:id"
                          element={<AddAttribute history={history} />}
                        />
                      </Route>
                      {/*  <Route
                        exact
                        path="/catalogue/products/edit/:id"
                        element={<PrivateRoute />}
                      >
                        <Route
                          exact
                          path="/catalogue/products/edit/:id"
                          element={<AddProduct history={history} />}
                        />
                      </Route> */}
                      {/* <Route
                        exact
                        path="/dashboard"
                        element={
                          <PrivateRoute
                            exact
                            path="/dashboard"
                            component={<Dashboard />}
                          />
                        }
                      /> */}
                      {/* <PrivateRoute path="/dashboard" element={<Dashboard />} /> */}
                      {/* TODO:Add No page found page  */}
                      <Route
                        exact
                        path="/admin/list"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/admin/list"
                          element={<AdminList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/admin/add"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/admin/add"
                          element={<AddAdmin history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/admin/edit/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/admin/edit/:id"
                          element={<AddAdmin history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/notifications"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/notifications"
                          element={<NotificationsList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/content/helpcenter"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/content/helpcenter"
                          element={<HelpCenter history={history} />}
                        />
                      </Route>{" "}
                      <Route
                        exact
                        path="/content/helpcenter/page"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/content/helpcenter/page"
                          element={<HelpCenterPages history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/content/helpcenter/page/list"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/content/helpcenter/page/list"
                          element={<HelpCenterPagesList history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/content/helpcenter/pages/edit/:id"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/content/helpcenter/pages/edit/:id"
                          element={<HelpCenterPages history={history} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/content/howitworks"
                        element={<PrivateRoute history={history} />}
                      >
                        <Route
                          exact
                          path="/content/howitworks"
                          element={<HowItWorks history={history} />}
                        />
                      </Route>
                    </Routes>
                  </Suspense>
                </ErrorBoundary>
              </Provider>
            </div>
            // <Provider store={store}>
            //   <ConnectedRouter history={history}>
            //<ErrorBoundary>
            //       <Suspense>
            //         <Routes>
            //           {/* Login Routes */}
            //           <Route exact path="/" element={<Login />} />

            //           {/* Page Not Found */}
            //           <Route path="*">
            //             {/* TODO:Add No page found page  */}
            //             <PageNotFound />
            //           </Route>
            //         </Routes>
            //       </Suspense>
            //     </ErrorBoundary>
            //   </ConnectedRouter>
            // </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}
