import React, { useState, useEffect, useCallback } from "react";
import AdminCard from "./AdminCard";
import {
  List,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
  Input,
  notification,
} from "antd";
import { connect } from "react-redux";
import { getAllAdmins } from "Actions/usersAction";
import StyledButton from "components/Form/StyledButton";
import { IoFilterOutline } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import axios from "AxiosInstance";
import "./Users.Css/userList.css";
// import apiWrapper from "merchantApiClientWrapper";
import Debounce from "lodash/debounce";
import UserRole from "Utils/roles";

function AdminList(props) {
  const { history, adminList, getAllAdmins } = props;
  const [pageSize, setpageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [role, setRole] = useState([]);
  const [filterEnable, setFilterEnable] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const { Title, Text } = Typography;

  function onShowSizeChange(current, pageSize) {
    setpageSize(pageSize);
  }
  //redux action on getting all the users
  useEffect(() => {
    // const getRole = async () => {
    //   const rolesResponse = await getAllUserRoles();
    //   const roles =
    //     rolesResponse?.data &&
    //     rolesResponse?.data?.length &&
    //     rolesResponse?.data?.filter((item) => {
    //       return item?.isActive;
    //     });
    //   // const userRole = UserRole(props.userRoles, "User Management");
    //   // setUserPermissions(userRole);
    //   // if (roles && roles.length) {
    //   //   setRole(roles);
    //   // }
    // };
    getAllAdmins(30, 0);
    // getRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllAdmins]);

  // const getAllUserRoles = async () => {
  //   try {
  //     setLoading(true);
  //     const getAllRoles = await axios.get(`Role`, {
  //       headers: {
  //         sessionId: localStorage.getItem("sessionId"),
  //       },
  //     });
  //     if (getAllRoles?.status === 200) {
  //       setLoading(false);
  //       return getAllRoles;
  //     }
  //   } catch {
  //     setLoading(false);
  //     notification.error({
  //       message: "There was some error in filtering user",
  //     });
  //   }
  // };

  const handleFilterStatus = async (status) => {
    try {
      setLoading(true);
      const getFiltereduser = await axios.get(
        `Users/getfilteredusers?status=${status}`,
        {
          headers: {
            sessionId: localStorage.getItem("sessionId"),
          },
        }
      );
      if (getFiltereduser.status === 200) {
        setFilteredUserList(getFiltereduser.data);
        setLoading(false);
      }
    } catch {
      setLoading(false);
      notification.error({
        message: "There was some error in filtering user",
      });
    }
  };

  // const handleFilterRole = async (role) => {
  //   try {
  //     setLoading(true);
  //     const getFiltereduser = await axios.get(
  //       `Users/getfilteredusers?roleId=${role}`,
  //       {
  //         headers: {
  //           sessionId: localStorage.getItem("sessionId"),
  //         },
  //       }
  //     );
  //     if (getFiltereduser.status === 200) {
  //       setFilteredUserList(getFiltereduser.data);
  //       setLoading(false);
  //     }
  //   } catch {
  //     setLoading(false);
  //     notification.error({
  //       message: "There was some error in filtering user",
  //     });
  //   }
  // };

  const getSearchedUser = async (searchParams) => {
    setFilterEnable(true);
    try {
      setLoading(true);
      const getFiltereduser = await axios.get(
        `Users/getfilteredusers?searchText=${searchParams}`,
        {
          headers: {
            sessionId: localStorage.getItem("sessionId"),
          },
        }
      );
      if (getFiltereduser.status === 200) {
        setFilteredUserList(getFiltereduser.data);
      }
      setLoading(false);
    } catch {
      notification.error({
        message: "There was some error in searching user",
      });
      setLoading(false);
    }
  };

  // wait for sometime untill the last key stroke and then call the api
  const onSearchUser = useCallback(
    Debounce((q) => getSearchedUser(q), 500),
    []
  );

  const menu = (
    <Menu className="export_dw">
      <span>Filter by Status</span>
      <Menu.Item
        key="0"
        onClick={() => {
          setFilterEnable(true);
          handleFilterStatus("");
        }}
      >
        All
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setFilterEnable(true);
          handleFilterStatus(true);
        }}
      >
        Active
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setFilterEnable(true);
          handleFilterStatus(false);
        }}
      >
        Inactive
      </Menu.Item>
      {/* <span>Filter by Role</span>
      <Menu.Item
        key="0"
        onClick={() => {
          setFilterEnable(true);
          // handleFilterRole("");
        }}
      >
        All
      </Menu.Item>
      {role &&
        role.map((item, index) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                setFilterEnable(true);
                // handleFilterRole(item.id);
              }}
            >
              {item.name}
            </Menu.Item>
          );
        })} */}
    </Menu>
  );

  return (
    <>
      <Row className="users_top_bar" style={{ marginBottom: "2%" }}>
        <Col lg={14} sm={12} md={8} xs={24}>
          <Title level={2}>Admins List</Title>
          <Text type="secondary">
            {filteredUserList.length > 0
              ? filteredUserList.length
              : adminList && adminList.length}{" "}
            {adminList?.length > 1 ? "Admins" : "Admin"}
          </Text>
        </Col>
        <Col lg={10} sm={12} md={16} xs={24}>
          <div className="user_right">
            <div className="search_table_full">
              {/* <Input
                bordered={false}
                placeholder="Search User"
                prefix={<FiSearch style={{ color: "lightgray" }} />}
                onChange={(e) => onSearchUser(e.target.value)}
                allowClear
              /> */}
            </div>
            <div className="userlisttop_right">
              {/* <div className="filter_right">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <span>
                    <IoFilterOutline />
                    Filter
                  </span>
                </Dropdown>
              </div> */}
              {/* {userPermissions.includes("Create") && ( */}
              <StyledButton
                type="primary"
                size="middle"
                htmlType="button"
                ghost
                onClick={() => props.history.push("/admin/add")}
              >
                Add New Admin
              </StyledButton>
              {/* )} */}
            </div>
          </div>
        </Col>
      </Row>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 5,
        }}
        dataSource={filterEnable ? filteredUserList : adminList}
        loading={props.loading || loading}
        pagination={{
          pageSize: pageSize,
          defaultCurrent: 1,
          pageSizeOptions: [10, 25, 30, 50],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
        }}
        renderItem={(item) => (
          <List.Item style={{ justifyContent: "center" }}>
            <AdminCard
              adminData={item}
              history={history}
              userPermissions={userPermissions}
            />
          </List.Item>
        )}
      />
    </>
  );
}

//injecting redux data to props
const mapStateToProps = (state) => {
  return {
    adminList: state?.users?.users,
    loading: state?.users?.loading,
    userRoles: state?.auth?.currentUser,
  };
};

export default connect(mapStateToProps, { getAllAdmins })(AdminList);
