import React, { useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  notification,
  Card,
  Drawer,
  Space,
} from "antd";
import axios from "axios";
import getImageUrl from "Utils/getImageUrl";
import SignUpForm from "Screens/SignUp/SignUpForm";

const LoginForm = (props) => {
  const { history } = props;
  const [loading, setloading] = useState(false);
  const [loginError, setloginError] = useState(false);
  const [merchantData, setMerchantData] = useState([]);
  const [open, setOpen] = useState(false);
  const { Text, Title } = Typography;
  const userData = useRef({});

  const loginSuccess = (value) => {
    localStorage.setItem("sessionId", value.sessionId);
    localStorage.setItem("merchantId", value.merchantId);
    history.push("/dashboard");
  };

  const onFinish = async (values) => {
    const { emailAddress } = values;
    values.email = emailAddress.toLowerCase();
    setloading(true);
    try {
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_MerchantDashboard_Api_URL}/Account/login`,
        values
      );

      if (loginResponse && loginResponse.data && loginResponse.status !== 200) {
        notification.error({ message: loginResponse.response.data.message });
        setloading(false);
      } else {
        if (loginResponse.data && loginResponse.data.length > 1) {
          userData.current = values;
          setMerchantData(loginResponse.data);
          localStorage.setItem("userData", JSON.stringify(loginResponse?.data));
          setloading(false);
        } else if (loginResponse?.data.isAuthenticated) {
          loginSuccess(loginResponse.data);
          localStorage.setItem("userData", JSON.stringify(loginResponse?.data));
        }
      }
    } catch (e) {
      setloginError(true);
      setloading(false);
    }
  };

  const getStoreLogo = (item) => {
    if (item?.storeLogoDark?.imageId) {
      return `${getImageUrl(
        item && item.storeLogoDark.imageId,
        "original",
        item.storeLogoDark.extension,
        item.merchantId
      )}`;
    } else if (item?.storeLogoLight?.imageId) {
      return `${getImageUrl(
        item.storeLogoLight.imageId,
        "original",
        item.storeLogoLight.extension,
        item.merchantId
      )}`;
    } else {
      return `https://testcdn.kuber.shop/noimage.png`; // Temporary image
    }
  };

  // call API for with merchantid
  const onSelectMerchant = async (merchantId) => {
    try {
      let selectedMerchantData = userData.current;

      selectedMerchantData.merchantId = merchantId;

      const loginWithSpecificMerchant = await axios.post(
        `${process.env.REACT_APP_MerchantDashboard_Api_URL}/Account/loginwithmerchantid`,
        selectedMerchantData
      );

      if (
        loginWithSpecificMerchant?.data &&
        loginWithSpecificMerchant.status !== 200
      ) {
        notification.error({
          message: loginWithSpecificMerchant.response.data.message,
        });
      } else {
        if (loginWithSpecificMerchant?.data?.isAuthenticated) {
          // set merchantId & session id
          loginSuccess(loginWithSpecificMerchant.data);
        }
      }
    } catch (e) {
      notification.error({
        message: e?.response?.data?.message || "Something went wrong on login.",
      });
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Login Form */}
      {merchantData && merchantData.length === 0 ? (
        <Form
          name="basic"
          onFinish={onFinish}
          onValuesChange={() => {
            setloginError(false);
          }}
          className="login_form_inp"
        >
          <Row className="login-fr" gutter={[0, 16]}>
            <Col span={24}>
              <label className="input-label">Email</label>
              <Form.Item
                //  validate inputs here
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                  {
                    type: "email",
                    message: "Please enter valid email!",
                  },
                ]}
                name="emailAddress"
              >
                <Input className="input-field" type="email" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label className="input-label">Password</label>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                name="password"
              >
                <Input.Password
                  className="input-field"
                  size="large"
                  style={{ position: "inherit" }}
                />
              </Form.Item>
              {loginError && (
                <Text style={{ color: "red" }}>
                  Please,check your email and password and try again.
                </Text>
              )}
            </Col>
          </Row>
          {/* <Row gutter={[0, 16]}>
            <Col span={12}>
              {" "}
              <Form.Item className="login_form_fp">
                <div style={{ justifyContent: "flex-start", display: "flex" }}>
                  <a href="#" onClick={showDrawer} role="button">
                    Sign Up
                  </a>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item className="login_form_fp">
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <a href="/forgot" role="button">
                    Forgot password ?
                  </a>
                </div>
              </Form.Item>
            </Col>
          </Row> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              disabled={loading}
            >
              {loading ? `Logging In...` : ` Login`}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Title level={3}>Please select your store :-</Title>
          <Row gutter={[16, 16]}>
            {merchantData &&
              merchantData.map((item) => {
                return (
                  <Col span={12} className="store-img">
                    <Card
                      cover={<img alt={``} src={getStoreLogo(item)} />}
                      hoverable
                      onClick={() => {
                        onSelectMerchant(item.merchantId);
                      }}
                    >
                      <Card.Meta title={item.storeName} />
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </div>
      )}
      <SignUpForm open={open} setOpen={setOpen} />
    </>
  );
};

export default LoginForm;
