//Common System User Card
import React, { useState } from "react";
import { Card, Row, Col, Typography, notification, Tag } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "././Users.Css/usercard.css";
import DeleteModal from "components/DeleteModal";
import { getAllAdmins } from "Actions/usersAction";
import { connect } from "react-redux";
// import apiWrapper from "merchantApiClientWrapper";
import axios from "AxiosInstance";

function AdminCard(props) {
  const { adminData, userPermissions } = props;
  const { firstName, email, phoneNumber, role, lastName } = adminData;
  const { Text } = Typography;
  const [visible, setVisible] = useState(false);

  const handleEdit = () => {
    props.history.push({
      pathname: `/admin/edit/${adminData?.id}`,
      state: { data: adminData },
    });
  };

  const handleDelete = () => {
    setVisible(true);
  };
  const userDataToken = JSON.parse(localStorage.getItem("userData"));

  const DeleteAdmin = async () => {
    try {
      // apiWrapper.user.delete(userData.id, sessionId);
      const response = await axios.delete(`Admin/${adminData?.id}`, {
        headers: {
          Authorization: `Bearer ${userDataToken?.token}`,
        },
      });
      if (response.status === 200) {
        setVisible(false);
        props.getAllAdmins();
        notification.success({
          message: "admin deleted successfully",
        });
        window.location.reload(false);
      } else {
        notification.error({
          message: response?.response?.data?.message,
        });
      }
    } catch (e) {
      notification.error({
        message: "Error deleting admin",
      });
    }
  };

  return (
    <>
      <Card className="card" hoverable>
        <Row justify="space-between" span={24}>
          <Col className="title-us" span={17}>
            <Card.Meta
              // title="Manoj"
              title={`${firstName}  ${lastName ? lastName : ""}`}
              // description={role?.name}
              // avatar={<Avatar src={DummyAvatar} size="large" />}
              className="title "
            />
          </Col>
          <Col span={7}>
            <Row justify="end">
              <EditOutlined onClick={handleEdit} />
              {/* {userPermissions.includes("Delete") && ( */}
              <DeleteOutlined
                onClick={handleDelete}
                style={{ paddingLeft: "15px" }}
              />
              {/* )} */}
            </Row>
          </Col>
        </Row>
        <div className="tags_btn" style={{ paddingTop: "20px" }}>
          <p>
            <InboxOutlined style={{ fontSize: "20px" }} />
            <Text className="userInfo">{email}</Text>
          </p>
          <p>
            <PhoneOutlined style={{ fontSize: "20px" }} />
            <Text className="userInfo">{phoneNumber}</Text>
          </p>
          <hr />
          <p>
            {/* <Tag>
              {adminData?.isEmailVerified
                ? "Email Verified"
                : "Verification Pending"}
            </Tag> */}
            <Tag>{adminData?.isActive ? "Active" : "Disabled"}</Tag>
          </p>
        </div>
      </Card>
      {visible && (
        <DeleteModal
          visible={visible}
          data={`${firstName}  ${lastName ? lastName : ""}`}
          callAction={DeleteAdmin}
          type="admin"
          setvisible={setVisible}
          avatar
        />
      )}
    </>
  );
}

//injecting redux data to props
const mapStateToProps = (state) => {
  return {
    userRoles: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, { getAllAdmins })(AdminCard);
