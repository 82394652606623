import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "antd";
// import StyledButton from "components/Form/StyledButton";
// import "./dashboard.css";
// import moment from "moment";
// import axios from "AxiosInstance";
// // import getPaymentStatusColor from "Utils/getPaymentStatusColor";
// // import { getCurrencyHtmlCodeFromCurrencyCode } from "Utils/getCurrencyHtmlCodeFromCurrencyCode";
// import { Link } from "react-router-dom";
// import UserRole from "Utils/roles";
import { connect } from "react-redux";
// import GetLocaleFormat from "Utils/getLocaleFormat";

function Dashboard(props) {
  // const aWeekAgo = moment().subtract(7, "d").format("YYYY-MM-DD");
  // const aYearAgo = moment().subtract(1, "Y").format("YYYY-MM-DD");
  // const tomorrowsDate = moment().add(1, "d").format("YYYY-MM-DD");
  // const [orderList, setOrderList] = useState([]);
  // const [orderSnapshot, setOrderSnapshot] = useState([]);
  // const [topSellingBrands, setTopSellingBrands] = useState([]);
  // const [topSellingProducts, setTopSellingProducts] = useState([]);
  // const [topSellingCategories, setTopSellingCategories] = useState([]);
  // const [date, setDate] = useState(
  //   `dateFrom=${aWeekAgo}&dateTo=${tomorrowsDate}`
  // );
  // const [orderTableLoading, setorderTableLoading] = useState(false);
  // const [userPermissions, setUserPermissions] = useState([]);

  // const maxOrdersToLoad = 10;

  // let defaultOrderValue = {
  //   pageSize: maxOrdersToLoad,
  //   dateFrom: aWeekAgo,
  //   dateTo: tomorrowsDate,
  // };

  // useEffect(() => {
  //   const userRole = UserRole(props.userRoles, "Order Management");
  //   setUserPermissions(userRole);

  //   const sessionId = localStorage.getItem("sessionId");
  //   async function getOrderSnapshot() {
  //     setDate(`dateFrom=${aWeekAgo}&dateTo=${tomorrowsDate}`);
  //     try {
  //       setorderTableLoading(true);
  //       const response = await axios.post(
  //         `Search/orderSnapshot`,
  //         defaultOrderValue,
  //         {
  //           headers: {
  //             sessionId: sessionId,
  //           },
  //         }
  //       );
  //       setOrderSnapshot(response && response.data);
  //       setOrderList(response && response.data ? response.data : []);
  //       setorderTableLoading(false);
  //     } catch (e) {
  //       setorderTableLoading(false);
  //     }
  //   }

  //   async function getTopSellingBrands() {
  //     setDate(`dateFrom=${aYearAgo}&dateTo=${tomorrowsDate}`);
  //     const response = await axios.get(`Search/topSellingResult?${date}`, {
  //       headers: {
  //         sessionId: sessionId,
  //       },
  //     });
  //     setTopSellingBrands(
  //       response && response.data && response.data.topSellingBrands
  //     );
  //     setTopSellingProducts(
  //       response && response.data && response.data.topSellingProducts
  //     );
  //     setTopSellingCategories(
  //       response && response.data && response.data.topSellingCategories
  //     );
  //   }

  //   try {
  //     if (userRole && userRole.includes("Read")) {
  //       getOrderSnapshot();
  //       getTopSellingBrands();
  //     }
  //   } catch (e) {}
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const data = [
  //   {
  //     title: "Order Id",
  //     render: (record) =>
  //       userPermissions.includes("Update") ? (
  //         <Link to={`/order/${record.id}`}>{record.orderNo}</Link>
  //       ) : (
  //         <p>{record.orderNo}</p>
  //       ),
  //     key: "orderNo",
  //   },
  //   {
  //     title: "Order Date",
  //     render: (record) =>
  //       moment(record && record.creationDate).format("DD/MM/YYYY"),
  //     key: "orderDate",
  //   },
  //   {
  //     title: "Name",
  //     render: (record) =>
  //       record &&
  //       record.customerDetails &&
  //       `${
  //         record.customerDetails.firstName
  //           ? record.customerDetails.firstName
  //           : "."
  //       } ${record.customerDetails.lastName}`,
  //     key: "Name",
  //   },
  //   {
  //     title: "Email",
  //     render: (record) =>
  //       record && record.customerDetails ? record.customerDetails.email : "--",
  //     key: "customerDetails.email",
  //   },
  //   {
  //     title: "Items",
  //     key: "items",
  //     render: (record) =>
  //       record && record.orderItems && record.orderItems.length,
  //   },
  //   {
  //     title: "Total",
  //     render: (record) =>
  //       record && record.total && `£ ${GetLocaleFormat(record.total)}`,
  //     key: "total",
  //   },
  //   {
  //     title: "Status",
  //     key: "paymentStatus",
  //     render: (record) =>
  //       getPaymentStatusColor(
  //         record && record.orderStatus && record.orderStatus.name
  //       ),
  //   },
  // ];
  return (
    <div className="dashboard_mains">
      <div className="page-heading">
        <h2>Dashboard</h2>
        {/* <p>Data for current week</p> */}
      </div>
      <Row gutter={[0, 20]}>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_top_card" loading={orderTableLoading}>
                <div className="text_tp">
                  <div className="left_text_db">
                    <div className="top_icon_db">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Group_4372"
                        data-name="Group 4372"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <path
                          id="Path_4531"
                          data-name="Path 4531"
                          d="M0,0H28V28H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_4532"
                          data-name="Path 4532"
                          d="M13.333,3l9.333,5.25v10.5L13.333,24,4,18.75V8.25L13.333,3"
                          transform="translate(0.667 0.5)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_406"
                          data-name="Line 406"
                          y1="5.25"
                          x2="9.333"
                          transform="translate(14 8.75)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_407"
                          data-name="Line 407"
                          y2="10.5"
                          transform="translate(14 14)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_408"
                          data-name="Line 408"
                          x1="9.333"
                          y1="5.25"
                          transform="translate(4.667 8.75)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_409"
                          data-name="Line 409"
                          x1="9.333"
                          y2="5.25"
                          transform="translate(9.333 6.125)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <div className="left_box">
                      <span>Orders</span>
                      <strong>
                        {orderSnapshot &&
                          orderSnapshot.totalOrder &&
                          orderSnapshot.totalOrder.toLocaleString("en-GB")}
                      </strong>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_top_card" loading={orderTableLoading}>
                <div className="text_tp">
                  <div className="left_text_db">
                    <div className="top_icon_db">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Group_4375"
                        data-name="Group 4375"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <path
                          id="Path_4533"
                          data-name="Path 4533"
                          d="M0,0H28V28H0Z"
                          fill="none"
                        />
                        <line
                          id="Line_410"
                          data-name="Line 410"
                          y1="7"
                          x2="7"
                          transform="translate(10.5 9.333)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <circle
                          id="Ellipse_197"
                          data-name="Ellipse 197"
                          cx="0.583"
                          cy="0.583"
                          r="0.583"
                          transform="translate(10.5 9.333)"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <circle
                          id="Ellipse_198"
                          data-name="Ellipse 198"
                          cx="0.583"
                          cy="0.583"
                          r="0.583"
                          transform="translate(16.333 15.167)"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_4534"
                          data-name="Path 4534"
                          d="M5,24V5.333A2.333,2.333,0,0,1,7.333,3H19a2.333,2.333,0,0,1,2.333,2.333V24l-3.5-2.333L15.5,24l-2.333-2.333L10.833,24,8.5,21.667,5,24"
                          transform="translate(0.833 0.5)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <div className="left_box">
                      <span>Sales</span>
                      <strong>
                        {orderSnapshot &&
                          orderSnapshot.orders &&
                          orderSnapshot.orders.length > 0 &&
                          getCurrencyHtmlCodeFromCurrencyCode(
                            orderSnapshot.orders[0].currencyCode
                          )}{" "}
                        {orderSnapshot &&
                          orderSnapshot.totalAmount &&
                          GetLocaleFormat(Number(orderSnapshot.totalAmount))}
                      </strong>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_top_card" loading={orderTableLoading}>
                <div className="text_tp">
                  <div className="left_text_db">
                    <div className="top_icon_db">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Group_4376"
                        data-name="Group 4376"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <path
                          id="Path_4535"
                          data-name="Path 4535"
                          d="M0,0H28V28H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_4536"
                          data-name="Path 4536"
                          d="M9.667,5H7.333A2.333,2.333,0,0,0,5,7.333v14a2.333,2.333,0,0,0,2.333,2.333H19a2.333,2.333,0,0,0,2.333-2.333v-14A2.333,2.333,0,0,0,19,5H16.667"
                          transform="translate(0.833 0.833)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <rect
                          id="Rectangle_1569"
                          data-name="Rectangle 1569"
                          width="7"
                          height="4.667"
                          rx="2"
                          transform="translate(10.5 3.5)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_411"
                          data-name="Line 411"
                          x2="0.012"
                          transform="translate(10.5 14)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_412"
                          data-name="Line 412"
                          x2="4"
                          transform="translate(13.5 14)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_413"
                          data-name="Line 413"
                          x2="0.012"
                          transform="translate(10.5 18.667)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Line_414"
                          data-name="Line 414"
                          x2="4"
                          transform="translate(13.5 18.667)"
                          fill="none"
                          stroke="#eb4a1c"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <div className="left_box">
                      <span>Items</span>
                      <strong>
                        {orderSnapshot &&
                          orderSnapshot.totalOrderItem &&
                          Number(orderSnapshot.totalOrderItem).toLocaleString(
                            "en-GB"
                          )}
                      </strong>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col> */}

        {/* <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_products_card" loading={orderTableLoading}>
                <div className="brands_title">
                  <span>Top Selling Products</span>
                </div>
                {topSellingProducts && topSellingProducts.length ? (
                  topSellingProducts.map((item, index) => {
                    if (index <= 4) {
                      return (
                        <div className="product_list_bx">
                          <div className="left_product_r">
                            <div className="pr_in">
                              <div className="prd_dsc">
                                <h6>{item.key}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="right_product_r">
                            <span>
                              £{" "}
                              {item.value &&
                                GetLocaleFormat(Number(item.value))}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <>
                    <p>No Sale has been made.</p>
                  </>
                )}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_products_card" loading={orderTableLoading}>
                <div className="brands_title">
                  <span>Top Selling Categories</span>
                </div>
                {topSellingCategories && topSellingCategories.length ? (
                  topSellingCategories.map((item, index) => {
                    if (index <= 4) {
                      return (
                        <div className="product_list_bx">
                          <div className="left_product_r">
                            <div className="pr_in">
                              <div className="prd_dsc">
                                <h6>{item.key}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="right_product_r">
                            <span>
                              £{" "}
                              {item.value &&
                                GetLocaleFormat(Number(item.value))}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <>
                    <p>No Sale has been made.</p>
                  </>
                )}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={24} xl={8}>
              <Card className="box_products_card" loading={orderTableLoading}>
                <div className="brands_title">
                  <span>Top Selling Brands</span>
                </div>
                {topSellingBrands && topSellingBrands.length ? (
                  topSellingBrands.map((item, index) => {
                    if (index <= 4) {
                      return (
                        <div className="product_list_bx">
                          <div className="left_product_r">
                            <div className="pr_in">
                              <div className="prd_dsc">
                                <h6>{item.key}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="right_product_r">
                            <span>
                              £{" "}
                              {item.value &&
                                GetLocaleFormat(Number(item.value))}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <>
                    <p>No Sale has been made.</p>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Col> */}
        {/* <Col span={24}>
          <Card className="ro_table_full">
            <div className="brands_title">
              <span>
                Recent Orders{" "}
                {orderList && orderList.totalOrder > maxOrdersToLoad
                  ? ` - Showing only latest ${maxOrdersToLoad} orders.`
                  : ""}
              </span>
            </div>
            <Table
              columns={data}
              dataSource={orderList && orderList.orders}
              pagination={false}
              className="recent_orders_table"
              loading={orderTableLoading}
              emptyText={"No orders have been placed recently"}
            />
            {orderList.totalOrder > maxOrdersToLoad && (
              <>
                <br />
                <StyledButton
                  type="primary"
                  size="middle"
                  htmlType="button"
                  onClick={() => props.history.push("/orders")}
                >
                  View All Orders
                </StyledButton>
              </>
            )}
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userRoles: state?.auth?.currentUser,
  };
};

export default connect(mapStateToProps, {})(Dashboard);
