import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Row,
  Col,
  Input,
  Typography,
  Select,
  Form,
  Button,
  notification,
} from "antd";
import { FiSearch } from "react-icons/fi";
import { IoFilterOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./customers.css";
import axios from "AxiosInstance";
import Debounce from "lodash/debounce";
import { IoIosClose } from "react-icons/io";
import "../Catalogue/Products/products.css";
import { connect } from "react-redux";
import UserRole from "Utils/roles";

const UsersList = (props) => {
  const [form] = Form.useForm();

  const { Title, Text } = Typography;
  const [customerData, setCustomerData] = useState();
  const [loading, setloading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [shippingCountries, setshippingCountries] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [userPermissions, setUserPermissions] = useState([]);

  const handleToggle = () => setFilters(!filters);

  const userDataToken = JSON.parse(localStorage.getItem("userData"));

  const customerParams = {
    pageIndex: currentIndex,
    pageSize: pageSize,
    searchText: "",
    customerType: "",
    isActive: "",
    subscribedToNewsletter: "",
    country: "",
  };

  const getCustomerData = async () => {
    setloading(true);
    const customerFilters = JSON.parse(
      localStorage.getItem("customer_filters")
    );
    if (customerFilters) {
      setFilters(true);
      setButtonsVisible(true);
      form.setFieldsValue({
        isActive: customerFilters.isActive,
        subscribedToNewsletter: customerFilters.subscribedToNewsletter,
        customerType: customerFilters.customerType,
        country: customerFilters.country,
      });
    }
    let params = customerParams;
    if (customerFilters) {
      params = customerFilters;
    }

    try {
      const response = await axios.get(`User`, {
        headers: {
          Authorization: `Bearer ${userDataToken?.token}`,
        },
      });
      setCustomerData(response && response?.data);
      setTotalCount(response && response?.data?.length);
    } catch (e) {
      notification.error({ message: "Error getting customers" });
      setloading(false);
    }
    setloading(false);
  };

  useEffect(() => {
    // call customer list API
    // const userRole = UserRole(props.userRoles, "Customer Management");
    // setUserPermissions(userRole);
    getCustomerData();
    // getShippingCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  // async function getShippingCountries() {
  //   const response = await axios.get(
  //     `Merchant/internationalSelling
  //   `,
  //     {
  //       headers: {
  //         sessionId: localStorage.getItem("sessionId"),
  //       },
  //     }
  //   );
  //   setshippingCountries(response.data && response.data.shippingCountries);
  // }

  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      render: (text, record) => {
        return (
          record && (
            <div className="media-in">
              <div className="media-body">
                <Title level={5}>
                  <Link to={`/users/${record.id}`}>{record?.userName}</Link>
                </Title>
              </div>
            </div>
          )
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Is Active",
      key: "isActive",
      render: (record) => (record && record.isActive === true ? "Yes" : "No"),
    },
    {
      title: "Is Blocked",
      key: "isBlocked",
      render: (record) => (record && record.isBlocked === true ? "Yes" : "No"),
    },
  ];

  const { Option } = Select;

  const getSearchData = async (query) => {
    let queryParams = customerParams;
    queryParams.searchText = query;
    try {
      setloading(true);
      const response = await axios.post(
        `Customer/searchcustomers`,
        queryParams,
        {
          headers: {
            sessionId: localStorage.getItem("sessionId"),
          },
        }
      );
      setCustomerData(response && response.data && response.data.customers);
      setTotalCount(response && response.data && response.data.totalCount);
      setloading(false);
    } catch (e) {
      setloading(false);
    }
  };

  // wait for sometime untill the last key stroke and then call the api
  const onSearchCustomer = useCallback(
    Debounce((q) => getSearchData(q), 500),
    []
  );

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setcurrentIndex(0);
  };

  const onPageChange = (current) => {
    setcurrentIndex(current - 1);
  };
  const onFinish = async (values) => {
    values.searchText = "";
    values.pageIndex = currentIndex;
    values.pageSize = pageSize;
    localStorage.setItem("customer_filters", JSON.stringify(values));
    try {
      setloading(true);
      const response = await axios.post(`Customer/searchcustomers`, values, {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      });
      setCustomerData(response && response.data && response.data.customers);
      setTotalCount(response && response.data && response.data.totalCount);
      setloading(false);
    } catch (e) {
      setloading(false);
    }
  };

  const onClearFilters = () => {
    localStorage.removeItem("customer_filters");
    setFilters(false);
    form.resetFields();
    setButtonsVisible(false);
    getCustomerData();
  };
  return (
    <>
      <Row
        style={{ marginTop: "0%", marginBottom: "2%" }}
        justify="space-between"
        className="users_top_bar"
      >
        <Col lg={14} sm={12} md={8} xs={24}>
          <Title level={2}>Users</Title>
          <Text type="secondary">
            {totalCount && totalCount > 1
              ? `${totalCount.toLocaleString("en-GB")} users`
              : `${totalCount} user`}
          </Text>
        </Col>
      </Row>
      {/* {filters && (
        <Form
          onFinish={onFinish}
          onValuesChange={() => setButtonsVisible(true)}
          form={form}
        >
          <div className="product_filter">
            <div className="close_pr" onClick={handleToggle}>
              <IoIosClose />
            </div>
            <Row gutter={[30, 0]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                <div className="pr_filter">
                  <label className="input-label">Customer Type</label>
                  <Form.Item name="customerType">
                    <Select
                      allowClear
                      showArrow
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value="RegisteredCustomer">
                        Registered Customer
                      </Option>
                      <Option value="GuestCustomer">Guest Customer</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                <div className="pr_filter">
                  <label className="input-label">Customer Status</label>
                  <Form.Item name="isActive">
                    <Select
                      allowClear
                      showArrow
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value="true">Active</Option>
                      <Option value="false">In-Active</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                <div className="pr_filter">
                  <label className="input-label">Newsletter</label>
                  <Form.Item name="subscribedToNewsletter">
                    <Select
                      allowClear
                      showArrow
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value="true">Subscribed</Option>
                      <Option value="false">Not Subscribed</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                <div className="pr_filter">
                  <label className="input-label">Country</label>
                  <Form.Item name="country">
                    <Select
                      allowClear
                      showArrow
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {shippingCountries &&
                        shippingCountries.map((item) => {
                          return <Option value={item.name}>{item.name}</Option>;
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="check_fil_list">
              {buttonsVisible && (
                <div>
                  <Button type="link" htmlType="submit">
                    Apply Filters
                  </Button>
                  <Button
                    type="text"
                    className="clear_btn"
                    onClick={onClearFilters}
                  >
                    Clear Selection
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form>
      )} */}
      <div className="coupon_list_table">
        <Table
          loading={loading}
          columns={columns}
          dataSource={customerData}
          bordered
          title={() => (
            <>
              <Row align="middle">
                <Col span={15}>
                  <h3 className="inv_title">Users List</h3>
                </Col>
                <Col span={7}>
                  <div className="search_table_full">
                    {/* <Input
                      bordered={false}
                      placeholder="Search"
                      prefix={<FiSearch style={{ color: "lightgray" }} />}
                      allowClear
                      onChange={(e) => onSearchCustomer(e.target.value)}
                    /> */}
                  </div>
                </Col>
                <Col span={2}>
                  <div className="inv_top_ft_ed">
                    {/* <span style={{ cursor: "pointer" }} onClick={handleToggle}>
                      <IoFilterOutline />
                      &nbsp; Filter
                    </span> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
          pagination={{
            pageSize: pageSize,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            total: totalCount,
            onChange: onPageChange,
          }}
        />
      </div>
    </>
  );
};

// injecting redux data to props
const mapStateToProps = (state) => {
  return {
    userRoles: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, {})(UsersList);
